<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap" oncontextmenu="return false" ondragstart="return false" onselectstart="return false">
        <div v-if="bbs.isSecret" class="pageInfo-area">
            <div class="page-info">
                <img src="@/assets/img/img-pageInfo1.png" alt="img" class="pi-img">
                <div class="pi-tit">이 페이지는 보안페이지입니다.</div>
                <div class="pi-txt txt1">
                    복사 및 외부유출이 금지되어 있어<br>
                    현재IP에서는 열람이 불가능 합니다.
                </div>
                <div class="pi-txt txt2">(문의 : 솔루션 개발팀)</div>
            </div>
        </div>
        <div v-else>
            <CarrotTitle title="고객사 별 매출순위">
                <div class="title-tipbox float-right">
                    <span class="float-right title-tip">보안 - 이 페이지는 보안페이지입니다. 복사 및 외부유출을 금합니다.</span>
                </div>
                - 현 페이지의 모든 금액은 승인완료 금액을(실 수령액)기준으로 합니다.<br>
                - CARROT 리드급 이상에게만 보여지는 페이지 입니다. 보안에 유의하시기 바랍니다
            </CarrotTitle>
            <div class="board">
                <div class="mt-40">
                    <div>
                        <table class="table-row table-serach">
                            <tbody>
                                <tr>
                                    <th width="200">기간</th>
                                    <td>
                                        <carrot-date-picker v-model="bbs.sdate" class="dp-inblock w-120px"></carrot-date-picker>
                                        ~
                                        <carrot-date-picker v-model="bbs.edate" class="dp-inblock w-120px ml-10"></carrot-date-picker>
                                    </td>
                                </tr>
                                <tr>
                                    <th width="200">고객사</th>
                                    <td>
                                        <carrot-company v-if="bbs.isLoaded==true" v-model="bbs.company" class="w-100per"></carrot-company>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button @click="bbs.doSearch" class="btn-default float-right mt-10">검색</button>
                        <div class="clear"></div>

                        <div class="mt-50 mb-20">
                            <select v-model="bbs.syear" @change="bbs.doSearch" class="w-100px float-left">
                                <option :value="v" v-for="(v, k) in bbs.search_years" :key="k">{{ v }}</option>
                            </select>
                            <div class="clear"></div>
                        </div>
                        <table class="table-col" v-if="bbs.analysis.length>0">
                            <colgroup>
                                <col :span="bbs.analysis.length">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>합계</th>
                                    <th v-for="(irow, i) in bbs.analysis" :key="i">{{ irow.ctype_name }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ bbs.number_format(bbs.total) }}</td>
                                    <td v-for="(irow, i) in bbs.analysis" :key="i">{{ bbs.number_format(irow.sales) }}</td>
                                </tr>
                            </tbody>
                        </table>



                        <table class="table-col mt-50" v-if="bbs.list.length>0"  style=" overflow:auto; height:600px;  white-space: nowrap;  display: block; ">
                            <colgroup>
                                <col width="60">
                                <col width="100">
                                <col width="110">
                                <col v-for="(irow, i) in bbs.list[0].offices" :key="i" >
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>랭킹</th>
                                    <th>고객사</th>
                                    <th>총매출</th>
                                    <th v-for="(irow, i) in bbs.list[0].offices" :key="i" >{{ irow.office_name }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(irow, i) in bbs.list" :key="i">
                                    <td>{{ irow.rank }}</td>
                                    <td>{{ irow.company_kname }}</td>
                                    <td>{{ bbs.number_format(irow.tot_sales) }}</td>
                                    <td v-for="(jrow, j) in irow.offices" :key="j">{{ bbs.number_format(jrow.sales) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue';
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue';
import CarrotCompany from '@/components/common/CarrotCompany.vue';
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'


export default {
    layout:"customerManagement",
    components: {
        CarrotDatePicker,
        CarrotCompany
    },
    setup() {
        const toast = useToast()
        const store = useStore();
        const router = useRouter();

        const bbs = reactive({
            isLoaded     : false,
            isSecret     : true,
            search_years : [],

            sdate   : "",
            edate   : "",
            company : "",
            syear   : "",

            analysis : [],
            list     : [],
            total    : 0,
            number_format: (num) => { 
                let price = num.toString().replace(/,/g, "");
                price = parseFloat(price);
                if(isNaN(price)) {
                    price = 0;
                }
                return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },

            doSearch : () => {
                let params = {
                    sdate   : bbs.sdate,
                    edate   : bbs.edate,
                    company : bbs.company,
                    syear   : bbs.syear
                };

                axios.get("/rest/customermgr/getTotalSalesList", { params : params }).then((res) => {
                    bbs.isSecret = res.data.is_secret;
                    if( res.data.err == 0 ){
                        bbs.list     = res.data.list;
                        bbs.analysis = res.data.analysis;
                        bbs.total    = res.data.total;
                        bbs.isLoaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            makeSearchYears: () => {
                let cd = new Date();
                let cy = cd.getFullYear();
                for(let i=cy; i>=2020; i--){
                    bbs.search_years.push(i);
                }
            }
        });

        onMounted(() => {
            // Mounted

            if(store.state.position!='리드'    ) {
              if(!store.state.isSalesAdmin){
                router.push({
                  path:"/main"
                });
                return;
              }
            }

            let cd = new Date();
            bbs.syear = cd.getFullYear();
            bbs.makeSearchYears();

            let ss = sessionStorage.getItem("statusCarrotTotalSalesList");
            if( ss ){
                ss = JSON.parse(ss);
                bbs.company = ss.company?ss.company:0;
                console.log(ss);
            }

            bbs.doSearch();
        });

        return {bbs};
    }
}
</script>

<style lang="scss" scoped>
</style>